import React from 'react'
import {motion, useViewportScroll, useTransform} from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import Arrow from '../assets/arrow.svg'
import { useMediaQuery } from 'react-responsive';


export default function BG() {
    const { scrollYProgress } = useViewportScroll();
    const yRange = useTransform(scrollYProgress, [0, 0.3], [1, 0.9]);
    const pinRange = useTransform(scrollYProgress, [0, 0.3], [0, -40]);
    const hasScrolled = useTransform(scrollYProgress, [0, 0.1], [1, 0]);

    const imgStyles = {
        scale: yRange,
        display: "flex", 
        justifyContent:"center",
        position: "relative",
        zIndex: 2
    }

    const pinStyles = {
        translateZ: pinRange
    }

    const stylesArrow = {
        opacity: hasScrolled
    }


    const variants = {
        animate: {
            y: -10,
            transition: {
                repeatType: "reverse",
                repeat: Infinity,
                duration: 0.5,
                ease: "easeOut"
            }
        }
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })

    
    return (
        
        <div className="landing__bg">
            <motion.div className="arrow" style={stylesArrow} >
                <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {repeatType:"reverse", repeat: Infinity, duration: 1.5, ease: "backIn"}}} >
                    <Arrow/>
                </motion.div>
                <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {repeatType:"reverse", repeat: Infinity, duration: 1.5, ease: "backIn", delay: 0.1}}} >
                    <Arrow/>
                </motion.div>
                <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {repeatType:"reverse", repeat: Infinity, duration: 1.5, ease: "backIn", delay: 0.2}}} >
                    <Arrow/>
                </motion.div>
            </motion.div>

            {isTabletOrMobile ? 
                <motion.div style={imgStyles}>
                    <StaticImage
                        src="../images/map2.png"
                        alt=""
                        quality="100"
                    />
                </motion.div>
            :
            <>
                <motion.div
                    className="pinleft"
                    variants={variants}
                    animate="animate"
                    style={pinStyles}
                >
                    <StaticImage src="../images/droppin.png" alt=""/>
                </motion.div>
                
                <motion.div
                    className="pinright"
                    variants={variants}
                    animate="animate"
                    style={pinStyles}
                >
                    <StaticImage src="../images/droppin.png" alt=""/>
                </motion.div>
                
                <motion.div style={imgStyles}>
                    <StaticImage
                        src="../images/map.png"
                        alt=""
                        quality="100"
                    />
                </motion.div>
            </>}
        </div>
    )
}
