import React, {useEffect} from 'react'
import {motion, useAnimation} from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import BG from '../components/BG'
import Underline from './Underline';


export default function About() {
    
    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.6
    });

    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
        if (!inView) {
          controls.start('hidden');
        }
      }, [controls, inView]);

    
    const h1variant = {
        hidden: {
            opacity: 0,
            x: '20vw',
        },
        visible: {
            opacity: 1,
            x: '15vw',
            transition: {
                duration: 1,
                ease: [.57,.04,.29,.99],
            }
        }
    }

    const linevariant = {
        hidden: {
            width: 0,
            x: 0,
        },
        visible: {
            width: '100%',
            x: '20%',
            transition: {
                duration: 1.2,
                delay: 0.2,
                ease: [.7,-0.02,.06,1.03],
            }
        }
    }


    const contentVariants = {
        init: {
            opacity: 0,
            y: -10
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1.6,
                delay: 0.3,
                ease: [0.5, 0.02, 0.6, 0.99]
            }
        }
    
    }


    return (
        <motion.div className="about" id="about" 
            >
            
            <motion.div
            variants={contentVariants}
            initial="init"
            animate="animate">
                <div style={{position: 'relative'}} >
                    <BG/>
                    <motion.div ref={ref} className="about__header"  id="mission">
                        <motion.h1  initial="hidden" animate={controls} variants={h1variant} >Our Mission</motion.h1>
                        <motion.div initial="hidden" animate={controls} variants={linevariant} ><Underline/></motion.div>
                    </motion.div>
                </div>
                    
                    <div className="about__content">
                    <motion.div className="about__content__left" >                    
                        <motion.p>
                            <span style={{fontWeight: "bold", color: "#512B80"}}>Fetch </span> 
                            is a digital marketplace and social media platform that will redefine the way that musicians discover and get booked for live gig opportunities.
                            <br/><br/>
                            We aim to provide up-and-coming artists with the tools necessary to market their performance abilities while simultaneously growing their fan bases amongst local audiences.
                             With a <span style={{fontWeight: "bold"}}>simple </span>and <span style={{fontWeight: "bold"}} >transparent booking</span> and 
                             <span style={{fontWeight: "bold"}}> payment system</span>, <span style={{fontWeight: "bold"}}>effortlesss event </span>and 
                             <span style={{fontWeight: "bold"}}> QR-Code ticket generation</span>, and a <span style={{fontWeight: "bold"}}> curated event discovery interface</span>, 
                             Fetch will become the premier platform for artists, hirers, and local fans seeking live events.
                            <br/><br/>
                            
                        </motion.p>
                    </motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}
