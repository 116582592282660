import React from 'react'
import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

const images = ["/teamimg.jpg", "/teamimg2.jpg", "/teamimg3.jpg", "/teamimg4.jpg", "teamimg5.jpg"]

const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 70 : -70,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 70 : -70,
        opacity: 0
      };
    }
  };
  
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

export default function TeamImages() {
    const [[page, direction], setPage] = useState([0, 0]);

    const pageRef = useRef(page);
    pageRef.current = page;

  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
    setCount(5);
  };

  const handleBtn = (e) => {
    paginate(e.target.value - imageIndex)
  }

  const [count, setCount] = useState(5);
  const countRef = useRef(count);
  countRef.current = count;

  useEffect(() =>{
    if(count == 0){
        paginate(1);
        setCount(5);
    }
  }, [count])

  useEffect(() => {
    const interval = setInterval(() => {
        setCount(countRef.current - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

    return (
        <div className="images">
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                key={page}
                src={images[imageIndex]}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                    x: { duration: 1, ease: "easeInOut" },
                    opacity: { duration: 1, ease: [.57,.04,.29,.99] }
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x);

                    if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                    } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                    }
                }}
                />
            </AnimatePresence>
            <div className="img__btns">
                <button className={imageIndex === 0 ? "active" : undefined} value={0} onClick={handleBtn}/>
                <button className={imageIndex === 1 ? "active" : undefined} value={1} onClick={handleBtn}/>
                <button className={imageIndex === 2 ? "active" : undefined} value={2} onClick={handleBtn}/>
                <button className={imageIndex === 3 ? "active" : undefined} value={3} onClick={handleBtn}/>
                <button className={imageIndex === 4 ? "active" : undefined} value={4} onClick={handleBtn}/>
            </div>
        </div>
    )
}
