import React from 'react'
import {motion} from 'framer-motion';

export default function Underline() {

    const variants = {
        animate: {
            backgroundColor: ['#8D2887', '#512b80', '#8542D8'],
            transition: {
                repeat: Infinity,
                repeatType: 'reverse',
                duration: 5,
                ease: 'linear'
            }
        }
    }

    return (
        <motion.span className="underline" variants={variants} animate="animate" >            
        </motion.span>
    )
}
