import React from 'react'
import Underline from './Underline'
import Facebook from "../assets/facebook.svg"
import LinkedIn from "../assets/linkedin.svg"
import Instagram from "../assets/instagram.svg"
import Mobile from "../assets/mobile.svg"
import Mail from "../assets/mail.svg"
import {motion} from 'framer-motion'



export default function Contact() {
    
    const svgStyles = {
    width: "40px"
}

    return (
        <motion.div className="contact" id="contact">
            <h1>Contact
            <div style={{display: 'flex'}}><Underline/></div>
            </h1>
            <div className="contact__content">
                <div style={{display: 'flex', alignItems: 'center', maxWidth: '300px'}}>
                    <Mobile />
                    <p><a href="tel:845-768-4969" style={{ color: "inherit"}}>+1 845-768-4969</a></p>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Mail />
                    <p><a href="mailto:contact@fetchmusic.io" style={{ color: "inherit"}}>contact@fetchmusic.io</a></p>
                </div>
            </div>
                <div className="contact__links">
                    {/* <a href="" target="_blank" rel="noopener noreferrer"><Instagram style={svgStyles}/></a>
                    <a href="" target="_blank" rel="noopener noreferrer"><Facebook style={svgStyles}/></a>
                    <a href="" target="_blank" rel="noopener noreferrer"><LinkedIn style={svgStyles}/></a> */}
                    <Instagram style={svgStyles}/>
                    <Facebook style={svgStyles}/>
                    <LinkedIn style={svgStyles}/>
                </div>

                <p className="contact__footer" >Copyright 2021 © Fetch Entertainment Inc. | All Rights Reserved.</p>
        </motion.div>
    )
}
