import React from 'react'
import {Formik, Form, Field} from 'formik'

export default function VenueForm() {
    return (
        <Formik
            >
            {() => (    
            <Form name="venue-form" data-netlify="true" data-netlify-honeypot="bot-field">
            
            <Field type="hidden" name="form-name"/>
            <Field type="hidden" name="bot-field"/>
            <Field type="hidden" name="venue_name"/>
            <Field type="hidden" name="venue_type"/>
            <Field type="hidden" name="venue_address"/>
            <Field type="hidden" name="hired_performers"/>
            <Field type="hidden" name="hired_performersP2"/>
            <Field type="hidden" name="musician_type"/>
            <Field type="hidden" name="email"/>
            <Field type="hidden" name="phone_number" />
            </Form>
            )}
        </Formik>
    )
}
