import React, {useState} from "react"
import '../styles/global.css'
import { AnimatePresence} from 'framer-motion';
import Main from '../components/Main'
import Loader from '../components/Loader'
import MusicianForm from "../components/MusicianForm";
import VenueForm from "../components/VenueForm";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import logo from '../images/logo.png'

export default function Home({data}) {

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="mainContainer">
      <Helmet link={[
        {
            rel: 'shortcut icon',
            type: 'image/png',
            href: `${logo}`,
         },
    ]}>
          <meta charSet="utf-8" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description}/>
          <meta name="keywords" content="fetch, music, gig"/>
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:image" content={data.site.siteMetadata.image} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={data.site.siteMetadata.description} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:url" content={data.site.siteMetadata.url} />
          <link rel="canonical" href={data.site.siteMetadata.url} />
      </Helmet>
      <AnimatePresence exitBeforeEnter>
      {isLoading ? (
      <Loader setIsLoading={setIsLoading} key="loader"/>
      )
      : (
      <Main key="main"/>
       )}
       
      </AnimatePresence> 
      <MusicianForm/>
       <VenueForm/>
       
    </div>
  )
}

export const query = graphql`
  query SiteInfo {
    site {
      siteMetadata {
        description
        image
        title
        url
      }
    }
}
`
