import React from 'react'
import {Formik, Form, Field} from 'formik'

export default function MusicianForm() {
    return (
        <Formik
            >
            {() => (    
            <Form name="musician-form" data-netlify="true" data-netlify-honeypot="bot-field">
            
            <Field type="hidden" name="form-name"/>
            <Field type="hidden" name="bot-field"/>
            <Field type="hidden" name="band_name"/>
            <Field type="hidden" name="musician_type"/>
            <Field type="hidden" name="musician_genre"/>
            <Field type="hidden" name="musician_genre_other"/>
            <Field type="hidden" name="legal_name"/>
            <Field type="hidden" name="gender"/>
            <Field type="hidden" name="musician_address"/>
            <Field type="hidden" name="performed_live"/>
            <Field type="hidden" name="performed_monthly"/>
            <Field type="hidden" name="social_link"/>
            <Field type="hidden" name="social_link2"/>
            <Field type="hidden" name="email"/>
            <Field type="hidden" name="phone_number" />
            </Form>
            )}
        </Formik>
    )
}
