import React from 'react'
import About from './About'
import Team from './Team'
import {motion, useViewportScroll, useTransform} from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';


export default function Content() {

    const { scrollYProgress } = useViewportScroll();

    const yRange = useTransform(scrollYProgress, 
        [0, 0.1, 0.25], [
        '#150125',
        '#512b80',
        '#dcd5e6', 
]);

    
    const bgstyles = {
        backgroundColor: yRange
    }


    return (
        <motion.div className="content" style={bgstyles}
            initial="init"
            animate="animate">
            <About />
            <div className="content__headline">
                <h2 style={{fontWeight: "bold"}}>We provide a dedicated platform for</h2>
                <div className="features">
                    <div>
                        
                        <StaticImage src="../images/feature3.png" alt="" placeholder="tracedSVG" />
                        <p>Musicians to be booked and featured</p>
                    </div>
                    
                    <div>
                        
                        <StaticImage src="../images/feature2.png" alt="" />
                        <p>Hirers to book local musicians for any event</p>
                    </div>
                    <div>
                        
                        <StaticImage src="../images/feature1.png" alt="" />
                        <p>Fans to explore events around them</p>
                    </div>
                </div>
            </div>
            <Team/>
        </motion.div>
    )
}
