import React, {useState, useEffect} from 'react'
import '../styles/global.css'
import {motion} from 'framer-motion';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Underline from './Underline';
import Skyscraper from '../assets/skyscrapers.svg'
import { StaticImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';


export default function Landing() {
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");
    const [showMsg, setShowMsg] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMsg(false);
        }, 4000);
        return () => clearTimeout(timer);
    });

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = e => {
        e.preventDefault();
        addToMailchimp(email) // listFields are optional if you are only capturing the email address.
        .then(data => {
          // I recommend setting data to React state
          // but you can do whatever you want (including ignoring this `then()` altogether)
          if(data.result === "error"){
              setMsg("The email you entered is not valid or is already subscribed!")
          } else
          setMsg(data.msg);
          setShowMsg(true);
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // see below for how to handle errors
        })
        setEmail("");
      }
        

      const bgVariants = {
          init: {
              background: "radial-gradient(85% 0% at bottom center, #8c2043 0%, #63174f 0%, #2e0a40 0%, #150125 60%)"
          },
          animate: {
              background: 'radial-gradient(85% 60% at bottom center, #8c2043 0%, #63174f 10%, #2e0a40 40%, #150125 60%)',
              transition: {
                  duration: 1.2,
                  ease: "easeOut"
              }
          },
          exit: {
              opacity: 0
          }
      }

      const contentVariants = {
          init: {
              opacity: 0
          },
          animate: {
              opacity: 1,
              transition: {
                  duration: 1,
                  delay: 0.5,
                  ease: [0.5, 0.02, 0.6, 0.99]
              }
          }
      }

      const imgVariants = {
          init: {
              scale: 0.95
          },
          animate: {
              scale: 1,
              transition: {
                duration: 1,
                delay: 0.5,
                ease: [0.5, 0.02, 0.6, 0.99]
            }
          }
      }

      const h1variant = {
        init: {
            x: 40,
        },
        animate: {
            x: 0,
            transition: {
                duration: 0.6,
                delay: 0.6,
                ease: [.57,.04,.29,.99],
            }
        }
    }

      const linevariant = {
        init: {
            width: 0,
            x: -20,
        },
        animate: {
            width: '100%',
            x: 0,
            transition: {
                duration: 1,
                delay: 0.6,
                ease: [.7,-0.02,.06,1.03],
            }
        }
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
    

    return (
        <motion.div className="landing" id="landing"
            variants={isTabletOrMobile ? {} : bgVariants} 
            initial="init" 
            animate="animate" >
            
            <motion.div className="landing__content"
                variants={contentVariants}
                initial="init"
                animate="animate">
                    
                    <motion.div className="landing__content__left"
                    initial="init" animate="animate" variants={imgVariants} >
                        <StaticImage
                            src="../images/homepage.png"
                            style={{transform: "translate(20px, 20px)"}}
                            width={320}
                            alt=""
                            quality="100"
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/fanpage.png"
                            width={320}
                            style={{transform: "translate(-80px, 40px)"}}
                            alt=""
                            quality="100"
                            placeholder="blurred"
                        />
                        
                    </motion.div>
                    
                    <motion.div className="landing__content__right"  >
                        <div>
                        <motion.h1 initial="init" animate="animate" variants={h1variant} >
                            Fetch
                        </motion.h1>
                        
                        </div>

                        <h2 style={{fontWeight: "bold"}}>
                            <motion.div initial="init" animate="animate" variants={linevariant} style={{display: 'flex'}}><Underline/></motion.div>
                            Musicians at your fingertips
                        </h2>
                        
                        <p className="miniHeader">
                            
                        </p>
                        
                        <p>
                        Coming soon to the App Store.<br/> Subscribe to learn more about Fetch and follow our progress.
                        </p>

                        
                        <div className="landing__form">
                            <form className="form" onSubmit={handleSubmit} >
                                <input type="email" value={email} onChange={onEmailChange} id="email" name="email" placeholder="Your email address" />  
                                <motion.button type="submit" 
                                    whileHover={{backgroundColor: '#661ac5'}}>
                                        SIGN UP
                                </motion.button>
                            </form>
                            <motion.p style={{fontSize: "14px"}} initial={{opacity: 0}} animate={showMsg ? {opacity: 1} : {}} >{msg}</motion.p>
                        </div>
                        <Skyscraper className="skyscrapers" />
                    </motion.div>
                    
                </motion.div>
            
        </motion.div>
    )
}
