import React, {useState, useEffect} from 'react'
import Landing from './Landing'
import Nav from './Nav'
import Contact from './Contact'
import Content from './Content'

export default function Main() {

    const [isAnimating, setIsAnimating] = useState(true);

    useEffect(() =>{
        const timer = setTimeout(() => {
            setIsAnimating(false);
        }, 2000);
        return () => clearTimeout(timer);
    })

    return (
        <div style={isAnimating ? {overflow: "hidden", height: "100vh"}: {overflow: "hidden", height: "auto"}} >
            <Nav/>
            <Landing/>
            <Content/>
            <Contact/>
        </div>
    )
}
