import React, { useState } from 'react'
import {motion} from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import Logo from '../assets/logo.svg'

const Loader = ({setIsLoading}) => {

    const imageVariants = {
        init: {
            scale: 0
        },
        animate: {
            scale: 1,
            transition: {
                delay: 0.2,
                duration: 0.7,
                type: 'spring',
                bounce: 0.5
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.3,
                ease: "easeOut",
                delay: 0.2
            }
        }
    }

    const circleVariants = {
        init: {
            scale: 0
        }, 
        animate: {
            scale: [1, 1.3, 1.4, 1.4, 1.8, 0],
            transition: {
                times: [0, .3, .6, .8, .85, 1],
                duration: 1.5,
            }
        }
    }

    const circlesContainer = {
        animate: {
            transition: {
                staggerChildren: 0.2,
            }
        }
    }

    const circles = {
        init: {
            opacity: 0
        },
        animate: {
            opacity:[0, 1, 0],
            transition: {
                times: [0, 0.1, 1],
                duration: 0.5
            }
        }
    }

    const [loadLogo,setLoadLogo] = useState(false);
    const [loadCircles,setLoadCircles] = useState(false);

      
    return (
        <motion.div className="loader" >

            {loadLogo &&
            <motion.div className="loader__image" 
            variants={imageVariants}
            initial="init"
            animate="animate"
            onAnimationComplete={() => setIsLoading(false)}
            exit="exit">
                <Logo />
            </motion.div>
                }

            <motion.div className="circleBig"
            variants={circleVariants}
            initial="init"
            animate="animate" 
            onAnimationComplete={() => setLoadLogo(true)}
            />

            <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 0.1}}
            transition={{duration: 0.1}}
            onAnimationComplete={() => setLoadCircles(true)}/>

            {loadCircles &&
            <motion.div className="circles"
                variants={circlesContainer}
                animate="animate"
                initial="init" >
                    <motion.div variants={circles} style={{width: '60px', height: '60px'}} />
                    <motion.div variants={circles} style={{width: '80px', height: '80px'}}/>
                    <motion.div variants={circles} style={{width: '90px', height: '90px'}}/>
                    <motion.div variants={circles} style={{width: '140px', height: '140px'}} />
                    <motion.div variants={circles} style={{width: '160px', height: '160px'}}/>
                    <motion.div variants={circles} style={{width: '180px', height: '180px'}}/>
            </motion.div>}
        </motion.div>
    )
}

export default Loader;