import React, {useEffect, useState} from 'react'
import Underline from './Underline'
import {AnimatePresence, motion,  useAnimation} from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TeamImages from './TeamImages';


export default function Team() {

    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
        if (!inView) {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const h1variant = {
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1,
                ease: [.57,.04,.29,.99],
            }
        }
    }

    const linevariant = {
        hidden: {
            width: 0,
            x: -20,
        },
        visible: {
            width: '100%',
            x: 0,
            transition: {
                duration: 1.2,
                delay: 0.2,
                ease: [.7,-0.02,.06,1.03],
            }
        }
    }

    const bgVariant = {
        hidden: {
            width: '90%',
            height: '70%'
        },
        visible: {
            width: '100%',
            height: '100%',
            transition: {
                duration: 1.6,
                ease: [.7,-0.02,.06,1.03],
            }
        }
    }

    const imgVariant = {
        hidden: {
            scale: 1.1
        },
        visible: {
            scale: 1,
            transition: {
                duration: 1.6,
                ease: [.7,-0.02,.06,1.03],
            }
        }
    }

    const teamTextVariant = {
        init: {
            opacity: 0
        },
        animate: {
            opacity: 1
        },
        exit: {
            opacity: 0
        }
    }

    const [musicianData, setMusicianData] = useState({
        musician_type: "",
        musician_genre: "",
        musician_genre_other: "",
        legal_name: "",
        gender: "",
        band_name: "",
        musician_address: "",
        performed_live: "",
        perform_monthly: "",
        social_link: "",
        social_link2: "",
        email: "",
        phone_number: ""
      });


    const [venueData, setVenueData] = useState({
        venue_name: "",
        venue_type: "",
        venue_address: "",
        hired_performers: "",
        hired_performersP2: "",
        musician_type: "",
        email: "",
        phone_number: ""
      });
      const [currentStep, setCurrentStep] = useState(-1);
      const [finalPage, setFinalPage] = useState(false);

      const encode = (data) => {
        return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
      }
    
      const makeRequest = (formData, form) => {
          console.log(formData)
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": form, ...formData })
          })
          .then(() => {
          })
          .catch(() => {
            alert('Error');
          })
      };

      const handleMusicianSubmit = (newData, final = false) => {
        setMusicianData((prev) => ({ ...prev, ...newData }));

        if(final){
            setFinalPage(true);
            setShowVenue(false);
            setShowMusician(false);
            makeRequest(newData, "musician-form");
            return;
        }
        
        setCurrentStep((prev) => prev + 1);
      }

      const handleVenueSubmit = (newData, final = false) => {
        setVenueData((prev) => ({ ...prev, ...newData }));

        if(final){
            setFinalPage(true);
            setShowVenue(false);
            setShowMusician(false);
            makeRequest(newData, "venue-form");
            return;
        }
        
        setCurrentStep((prev) => prev + 1);
    }

    const handleMusicianPrev = (newData) => {
        setMusicianData((prev) => ({ ...prev, ...newData }));
        setCurrentStep((prev) => prev - 1);  
        
        if(currentStep < 0){
        setShowVenue(false);
        setShowMusician(false);
        }
      };
    
    
      const handleVenuePrev = (newData) => {
        setVenueData((prev) => ({ ...prev, ...newData }));
        setCurrentStep((prev) => prev - 1);  
        
        if(currentStep < 0){
        setShowVenue(false);
        setShowMusician(false);
        }
      };
    
      const [showVenue, setShowVenue] = useState(false);
      const [showMusician, setShowMusician] = useState(false);

      const handleMusicianForm = () =>{
        setShowMusician(true);
        setShowVenue(false);
        setCurrentStep((prev) => prev + 1);
      }

      const handleVenueForm = () =>{
        setShowVenue(true);
        setShowMusician(false);
        setCurrentStep((prev) => prev + 1);
      }

      const venueSteps = 
      [<VenueOne submit={handleVenueSubmit} data={venueData} prev={handleVenuePrev}/>,
        <VenueTwo submit={handleVenueSubmit} data={venueData} prev={handleVenuePrev}/>,
        <VenueThree submit={handleVenueSubmit} data={venueData} prev={handleVenuePrev}/>];

        const musicianSteps = 
        [<MusicianOne submit={handleMusicianSubmit} data={musicianData} prev={handleMusicianPrev}/>,
        <MusicianTwo submit={handleMusicianSubmit} data={musicianData} prev={handleMusicianPrev}/>,
        <MusicianThree submit={handleMusicianSubmit} data={musicianData} prev={handleMusicianPrev}/>,
        <MusicianFour submit={handleMusicianSubmit} data={musicianData} prev={handleMusicianPrev}/>,
        <MusicianFive submit={handleMusicianSubmit} data={musicianData} prev={handleMusicianPrev}/>];


    return (
        <motion.div ref={ref} className="team" id="team" >
            
            <motion.div  className="team__bg" variants={bgVariant} initial="hidden" animate={controls} />
            
            <div className="team__container">
            <TeamImages/>
            
            <div className="team__content">
             
                <motion.div  className="team__content__header">
                    <motion.h1 initial="init" animate={controls} variants={h1variant}>
                        Work with Us
                    </motion.h1>
                    <motion.div animate={controls} variants={linevariant} ><Underline/></motion.div>
                </motion.div>

                
                <AnimatePresence exitBeforeEnter>
                    {currentStep < 0 ?
                    <motion.div 
                        key={currentStep}
                        variants={teamTextVariant}
                        initial="init"
                        animate="animate"
                        exit="exit">
                        <div className="team__content__text">
                            <p>We are looking for musicians and hirers that are interested in our services and would like to participate in the beta testing of our app.<br/><br/> 
                            Fill out our quick questionnaire below and we will reach out to you.
                                </p><br/><br/>
                            </div>
                            <h2>Are you a musician or a hirer?</h2>
                            <div className="team__form__btn">
                                <motion.button type="button" onClick={handleMusicianForm}
                                whileHover={{backgroundColor: "#8542D8" }} >Musician</motion.button>
                                <motion.button type="button" onClick={handleVenueForm}
                                whileHover={{backgroundColor: "#8542D8" }} >Hirer</motion.button>
                            </div>
                    </motion.div>
             
                    :
                    <motion.div 
                        key={currentStep}
                        variants={teamTextVariant}
                        initial="init"
                        animate="animate"
                        exit="exit">
                        
                        {showVenue && 
                        venueSteps[currentStep]}
                        
                        {showMusician && 
                        musicianSteps[currentStep]}

                        {finalPage &&
                        <div className="team__final">
                            <h2>Thank you for submitting the questionnaire!</h2>
                            <p>We will reach out to you soon</p>
                        </div>}
                    
                    </motion.div>}

                </AnimatePresence>
            </div>
            </div>
        </motion.div>
    )
    
}


const MusicianOne = (props) => {
    const handleSubmit = (values) => {
        props.submit(values);
      };

    return(
        <Formik
        // validationSchema={venueOneValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        >
            {({values}) => (
            <Form>
            <div>
            <p>Are you a...</p>
            <ErrorMessage name="musician_type" />

            <div className="radio">
                <Field id="band" type="radio" name="musician_type" value="Band" />
                <motion.label htmlFor="band" whileHover={{boxShadow: "0 0 5px #512b80"}} >            
                    Band
                </motion.label>
                
                <Field id="dj" type="radio" name="musician_type" value="DJ" />
                <motion.label htmlFor="dj" whileHover={{boxShadow: "0 0 5px #512b80"}}>
                    DJ
                </motion.label>

                <Field id="ensemble" type="radio" name="musician_type" value="Ensemble" />
                <motion.label htmlFor="ensemble" whileHover={{boxShadow: "0 0 5px #512b80"}}>            
                    Ensemble Group
                </motion.label>
                
                <Field id="solo" type="radio" name="musician_type" value="Solo" />
                <motion.label htmlFor="solo" whileHover={{boxShadow: "0 0 5px #512b80"}}>
                    Solo Musician
                </motion.label>

                <Field id="singer" type="radio" name="musician_type" value="Singer" />
                <motion.label htmlFor="singer" whileHover={{boxShadow: "0 0 5px #512b80"}}>
                    Singer
                </motion.label>
            </div>
            
            <div style={{display: "flex"}}>
            {values.musician_type === 'Band' && 
            <Field name="musician_genre" as="select">
                <option>Select one</option>
                {bandList.map((item) =>
                <option key={item} value={item}>{item}</option>
                )}
            </Field>}
            
            
            {values.musician_type === 'DJ' && 
            <Field name="musician_genre" as="select">
                <option>Select one</option>
                {djList.map((item) =>
                <option key={item} value={item}>{item}</option>
                )}
            </Field>}

            {values.musician_type === 'Ensemble' && 
            <Field name="musician_genre" as="select">
                <option>Select one</option>
                {ensembleList.map((item) =>
                <option key={item} value={item}>{item}</option>
                )}
            </Field>}

            {values.musician_type === 'Solo' && 
            <Field name="musician_genre" as="select">
                <option>Select one</option>
                {soloList.map((item) =>
                <option key={item} value={item}>{item}</option>
                )}
            </Field>}

            {values.musician_type === 'Singer' && 
            <Field name="musician_genre" as="select">
                <option>Select one</option>
                {singerList.map((item) =>
                <option key={item} value={item}>{item}</option>
                )}
            </Field>}

            {values.musician_genre === 'Other' &&
            <Field name="musician_genre_other"/>}
            
            </div>
            </div>
            <div className="team__form__btn">
                <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
                <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Next</motion.button>
            </div>

            </Form>
        )}
        </Formik>
    )
}



const MusicianTwo = (props) => {
    const handleSubmit = (values) => {
        props.submit(values);
      };

    return(
        <Formik
        // validationSchema={venueOneValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        >
            {({values}) => (
            <Form>
                <p>Your legal name?</p>
                <ErrorMessage name="legal_name" />
                <Field name="legal_name" />

                <p>Gender?</p>
                <ErrorMessage name="gender" />
                <Field name="gender" />

                <p>Name of band/group or stage name?</p>
                <ErrorMessage name="band_name" />
                <Field name="band_name" />
                
                <div className="team__form__btn">
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Next</motion.button>
                </div>

            </Form>
        )}
        </Formik>
    )
}

const MusicianThree = (props) => {
    const handleSubmit = (values) => {
        props.submit(values);
      };

    return(
        <Formik
        // validationSchema={venueOneValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        >
            {({values}) => (
            <Form>

                <p>Where are you based?</p>
                <ErrorMessage name="musician_address" />
                <Field name="musician_address" />
                
                <p>Have you performed live in the past?</p>
                <ErrorMessage name="performed_live" />
                <Field name="performed_live" />

                <p>How often would you like to perform per month?</p>
                <ErrorMessage name="perform_monthly" />
                <Field name="perform_monthly" />
                
                <div className="team__form__btn">
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Next</motion.button>
                </div>

            </Form>
        )}
        </Formik>
    )
}

const MusicianFour = (props) => {
    const handleSubmit = (values) => {
        props.submit(values);
      };

    return(
        <Formik
        // validationSchema={venueOneValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        >
            {({values}) => (
            <Form>
                <p>Link to social media page(s)?</p>
                <ErrorMessage name="social_link" />
                <Field name="social_link" />

                <p>Link to Spotify, Soundcloud, Youtube, Bandcamp, or other?</p>
                <ErrorMessage name="social_link2" />
                <Field name="social_link2" />
                
                <div className="team__form__btn">
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Next</motion.button>
                </div>

            </Form>
        )}
        </Formik>
    )
}

const MusicianFive = (props) => {
    const handleSubmit = (values) => {
        props.submit(values, true);
      };

    return(
        <Formik
        // validationSchema={venueOneValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        >
            {({values}) => (
            <Form name="musician-form" data-netlify="true" data-netlify-honeypot="bot-field">
                
                <Field type="hidden" name="form-name" />
                <Field type="hidden" name="bot-field"/>
                
                <p>Email Address?</p>
                <ErrorMessage name="email" />
                <Field name="email" />

                <p>Phone Number?</p>
                <ErrorMessage name="phone_number" />
                <Field name="phone_number" />
                
                <div className="team__form__btn">
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
                    <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Submit</motion.button>
                </div>

            </Form>
        )}
        </Formik>
    )
}



const VenueOne = (props) => {

    const handleSubmit = (values) => {
        props.submit(values);
      };

    return(
        <Formik
       // validationSchema={venueOneValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
>
  {({values}) => (
    <Form>
      <p>Name of venue or event space?</p>
      <ErrorMessage name="venue_name" />
      <Field name="venue_name" />      

      <p>Type of venue or event space?</p>
      <ErrorMessage name="venue_type" />
      <Field name="venue_type" />      

      <p>Address of venue or event space?</p>
      <ErrorMessage name="venue_address" />
      <Field name="venue_address" />
      
      <div className="team__form__btn">
        <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
        <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Next</motion.button>
      </div>

    </Form>
  )}
</Formik>

    )
}

const VenueTwo = (props) => {
    const handleSubmit = (values) => {
        props.submit(values);
      };
    return(
        <Formik
        // validationSchema={venueTwoValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        >
        {({values}) => (
        <Form>
        <p>Have you hired live performers before?</p>
        <ErrorMessage name="hired_performers" />
      <div className="radio">
        
        <Field id="yes" type="radio" name="hired_performers" value="Yes" />
        <motion.label htmlFor="yes" whileHover={{boxShadow: "0 0 5px #512b80"}}>            
            Yes
        </motion.label>
        
        <Field id="no" type="radio" name="hired_performers" value="No" />
        <motion.label htmlFor="no" whileHover={{boxShadow: "0 0 5px #512b80"}}>
            No
        </motion.label>
      </div>

      {values.hired_performers === "Yes" &&
      <>
      <p>On average, how many times per month have you hired live performers in the past?</p>
      <ErrorMessage name="hired_performersP2" />
      <Field name="hired_performersP2" />
      
      </> }
      {values.hired_performers === "No" &&
      <>
      <p>How often would you like to hire live musicians for your venue or event calendar?</p>
      <ErrorMessage name="hired_performersP2" />
      <Field name="hired_performersP2" />
      
      </>
    }

      <p>What type of live musician or band would you be most interested in booking?</p>
      <ErrorMessage name="musician_type" />
      <Field name="musician_type" />      
      
      <div className="team__form__btn">
        <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
        <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Next</motion.button>
      </div>
        </Form>
        )}
    </Formik>
    )
}

const VenueThree = (props) => {
    const handleSubmit = (values) => {
        props.submit(values, true);
      };
    return(
        <Formik
        // validationSchema={venueThreeValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        >
        {({values}) => (
        <Form name="work-f" data-netlify="true" data-netlify-honeypot="bot-field">

            <Field type="hidden" name="form-name" value="work-f" />
            <Field type="hidden" name="bot-field"/>

            <p>Email Address?</p>
            <Field name="email" />
            <ErrorMessage name="email" />

            <p>Phone Number?</p>
            <Field  name="phone_number" />
            <ErrorMessage name="phone_number" />

            <div className="team__form__btn">
                <motion.button whileHover={{backgroundColor: "#8542D8"}} type="button" onClick={() => props.prev(values)}>Go back</motion.button>
                <motion.button whileHover={{backgroundColor: "#8542D8"}} type="submit">Submit</motion.button>
            </div>

        </Form>
        )}
    </Formik>
    )
}


const venueOneValidationSchema = Yup.object({
    venue_name: Yup.string().required("* Required"),
    venue_type: Yup.string().required("* Required"),
    venue_address: Yup.string().required("* Required")
  });

  const venueTwoValidationSchema = Yup.object({
    hired_performers: Yup.string().required("* Required"),
    hired_performersP2: Yup.string().required("* Required"),
    musician_type: Yup.string().required("* Required")
  });

  const phoneRegExp = /^([0-1]([\s-./\\])?)?(\(?[2-9]\d{2}\)?|[2-9]\d{3})([\s-./\\])?(\d{3}([\s-./\\])?\d{4}|[a-zA-Z0-9]{7})$/;

  const venueThreeValidationSchema = Yup.object({
    phone_number: Yup.string().matches(phoneRegExp, '* Invalid phone number').required("* Required"),
    email: Yup.string().email("* Invalid email").required("* Required")
  });

  
  const bandList = ['Acoustic','Alternative','Americana','Big Band','Bluegrass','Blues','Christian','Classic Rock','Country','Cover','Dance','Disco','Folk','Funk','Heavy Metal','Indie','Jazz','Mariachi','Motown','Oldies','Party','Pop','Punk','R&B','Rock','Soul','Steel Drum','Swing','Top 40','Tribute','Wedding','Other'];
  const djList = ['Bar Mitzvah','Club','Event','Karaoke','Mobile','Prom','Radio','Wedding','Other'];
  const ensembleList = ['Big Band','Brass','Chamber Orchestra','Classical','Handbell','Marching','String Trio','String Quartet','Soul Band','Steel Drum Band','Swing Band','Top 40 Band','Tribute Band','Wedding Band','Other'];
  const soloList = ['Accordion Player','Bagpiper','Bass Player','Cellist','Classical Guitarist','Classical Pianist','Drummer','Dueling Pianist','Dulcimer Player','Flutist','Guitarist','Harpist','Keyboard Player','One Man Band','Organist','Percussionist','Pianist','Saxophone Player','Steel Drum Player','Trumpet Player','Violinist','Other'];
  const singerList = ['A Cappela Group','Barbershop Quartet','Carolers','Choir Group','Classical Singer','Country Singer','Crooner','Doo Wop Group','Gospel Group','Gospel Singer','Hip-Hop','Jazz','Pop','Praise','R&B','Rap','Singer/Songwriter','Wedding Singer','Other'];