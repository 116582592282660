import React, {useState} from 'react'
import {Link} from 'react-scroll'
import {motion, useViewportScroll, useTransform, AnimatePresence} from 'framer-motion'
import Underline from './Underline'
import Menu from '../assets/menu.svg'
import { StaticImage } from 'gatsby-plugin-image'

export default function Nav() {
    const { scrollYProgress } = useViewportScroll();
    const yRange = useTransform(scrollYProgress, [0, 0.02], ['#0f001b00', '#0f001b']);
    const linePos = useTransform(scrollYProgress, [0, 0.2, 0.4, 0.6, 0.8, 0.9, 0.95], [0, 0, 90, 90, 200, 200, 345]);
    const lineWidth = useTransform(scrollYProgress, [0, 0.2, 0.4, 0.6, 0.8, 0.9, 0.95], [45, 45, 60, 60, 95, 95, 55]);

    
    const navStyles = {
        backgroundColor: yRange,
    }


    const lineStyles = {
        x: linePos,
        width: lineWidth
    }

    const variants = {
        init: {
            y: -80
        },
        animate: {
            y: 0,
            height: 50,
            transition: {
                duration: 0.8,
                delay: 0.2,
                ease: "easeOut"
            }
        },
        animateOpen:{
            y:0,
            height: 90,
            transition: {
                duration: 0.6,
                ease: "easeOut"
            }
        }
    }

    const menuVariants = {
        init: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                duration: 0.8,
                ease: "easeIn"
            }
        },
        
        exit: {
            opacity: 0,
            transition: {
                duration: 0.4,
                ease: "easeOut"
            }
        }
    }

    const [isVisible, setIsVisible] = useState(false)

    const handleMenu = () => {
        setIsVisible(!isVisible)
    }

    return (
        <motion.div className="nav"
            variants={variants}
            initial="init"
            animate={isVisible ? "animateOpen" : "animate"}
            style={navStyles}
        >
            <div className="nav__content">

                <div className="nav__logo">
                    <Link to="landing" smooth duration={1600} activeClass="active" spy={true} >
                        <StaticImage src="../images/logo.png" alt="" width={48} placeholder="blurred" quality={100} />
                    </Link>
                </div>

                <div className="nav__links"  >
                    
                    <motion.div className="nav__links__underline" style={lineStyles}>
                        <Underline />
                    </motion.div>
                    
                    <Link to="landing" smooth duration={1600} activeClass="active" spy={true} >
                        <motion.div whileHover={{color: "#8542D8"}} >
                            Home
                        </motion.div>
                    </Link>

                    <Link to="mission" smooth duration={1600} activeClass="active" spy={true} offset={-100} >
                        <motion.div whileHover={{color: "#8542D8"}} >
                            Mission
                        </motion.div>
                    </Link>

                    <Link to="team" smooth duration={1600} activeClass="active" spy={true} offset={-20}>
                        <motion.div whileHover={{color: "#8542D8"}} >
                            Work with Us
                        </motion.div>
                    </Link>
                    
                    <Link to="contact" smooth duration={1600} activeClass="active" spy={true} >
                        <motion.div whileHover={{color: "#8542D8"}} >
                            Contact
                        </motion.div>
                    </Link>
                
                </div>

                <div className="nav__menu" onClick={handleMenu}>
                    <Menu/>
                </div>
            </div>

            <AnimatePresence>
                {isVisible  &&            
                <motion.div className="nav__links mobile" 
                    variants={menuVariants}
                    initial="init"
                    animate="animate"
                    exit="exit"
                    >
                                
                    <Link to="landing" smooth duration={1600} activeClass="active" spy={true} >
                        <motion.div >
                            Home
                        </motion.div>
                    </Link>

                    <Link to="mission" smooth duration={1600} activeClass="active" spy={true} offset={-100} >
                        <motion.div  >
                            Mission
                        </motion.div>
                    </Link>

                    <Link to="team" smooth duration={1600} activeClass="active" spy={true} offset={80}>
                        <motion.div  >
                            Work with Us
                        </motion.div>
                    </Link>
                    
                    <Link to="contact" smooth duration={1600} activeClass="active" spy={true} >
                        <motion.div  >
                            Contact
                        </motion.div>
                    </Link>
                
                </motion.div>}
            </AnimatePresence>



        </motion.div>
    )
}
